import './App.scss';

import {Switch, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy} from 'react';
import RingLoader from 'react-spinners/RingLoader';

// private route
import PrivateRoute from './components/privateRoute/PrivateRoute';

// pages
import Page404 from './pages/page404/Page404';
import SignIn from './pages/onboarding/signIn/SignIn';

// lazy-loading-dashboard
const Dashboard = lazy (() => import('./pages/main/dashboard/Dashboard'));

const App = () => {
  return (
    <main className="App">
      <Suspense
        fallback={
          <div className="loader">
            <RingLoader color={'#02ffe9'} size={130} />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact >
            <Redirect to='/admin-cryptodata-9427843d-e2e2-4816-bdc8-d945d6a36763' />
          </Route>
          <Route path="/admin-cryptodata-9427843d-e2e2-4816-bdc8-d945d6a36763" exact component={SignIn} />
          <PrivateRoute path="/admin-cryptodata-dashboard-68721sh1-ehjjh2e2-997-bd78c9098-112kai" exact component={Dashboard} />
          <Route path="*" exact component={Page404} />
        </Switch>
      </Suspense>
    </main>
  );
};

export default App;

import React, {useState, useEffect} from 'react';

// styling
import './SignIn.scss';

// @mui
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';

// component
import OnboardingNav from '../../../components/onboardingNav/OnboardingNav';

// utils
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { isLogin, login } from '../../../utils/login';


const onboardingTheme = createTheme ({
  palette: {
    primary: {
      main: '#6c17ff',
    },
    secondary: {
      main: '#02ffe9',
    },
  },
});

const SignIn = () => {
  const [signInObj, setSignInObj] = useState ({
    email: '',
    password: '',
    showPassword: false,
  });

  const history = useHistory();

  const handleInputChange = event => {
    setSignInObj ({
      ...signInObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setSignInObj ({
      ...signInObj,
      showPassword: !signInObj.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  useEffect(() => {
    if(isLogin()) {
      history.push('/admin-cryptodata-dashboard-68721sh1-ehjjh2e2-997-bd78c9098-112kai')
    }
  }, [history])

  const handleSignIn = async () => {
    try {
      const obj = {
        email: signInObj.email,
        password: signInObj.password,
      };

      let response = await axios.post (
        process.env.REACT_APP_EDAIN_URL + '/api/auth/signin',
        obj
      );

      if (response.status === 200) {
        cogoToast.success("Successfully connected!");
        login(response.data.token);
        history.push('/admin-cryptodata-dashboard-68721sh1-ehjjh2e2-997-bd78c9098-112kai');
      }

    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            return cogoToast.error('Invalid user or password');
          case 404:
            return cogoToast.error('User not found');
          case 422:
            return cogoToast.error('Email and password must have between 8 and 30 characters');
          default:
            return cogoToast.error('Ooops... Looks like something went wrong.');
        }
      }
    }
  };

  const handleSignInOnEnter = (event) => {
    if (event.keyCode === 13) handleSignIn();
  }

  return (
    <ThemeProvider theme={onboardingTheme}>
      <div className="sign-in">
        <OnboardingNav />
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="E-mail"
            name="email"
            color="secondary"
            onKeyUp={handleSignInOnEnter}
            value={signInObj.email}
            type="email"
            onChange={handleInputChange}
            focused
            sx={{mb: 5}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="email icon" edge="end">
                    <EmailIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {color: '#ffffff'},
            }}
          />
          <TextField
            label="Password"
            name="password"
            id="password-input"
            onKeyUp={handleSignInOnEnter}
            value={signInObj.password}
            type={signInObj.showPassword ? 'text' : 'password'}
            color="secondary"
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {signInObj.showPassword
                      ? <VisibilityOff />
                      : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{mb: 5}}
            focused
            InputLabelProps={{
              style: {color: '#ffffff'},
            }}
          />
          <Button
            onClick={handleSignIn}
            variant="contained"
            color="primary"
            id="sign-in-button"
          >
            Sign In
          </Button>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default SignIn;

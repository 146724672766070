import React, { useState } from "react";
import "./Page404.scss";
import ParticleEffectButton from "react-particle-effect-button";
import { useHistory } from "react-router-dom";

const Page404 = () => {
   const [hiddenParticles, setHiddenParticles] = useState(() => false);

   const animation = () => setHiddenParticles(!hiddenParticles);

   const history = useHistory();

   return (
      <div className="d-flex flex-column justify-content-center align-items-center page-404">
         <div className="overlay">
            <h2 className="text-center text-white error">Error 404</h2>
            <h4 className="text-center text-white">
               Sorry, we couldn't find the page you were looking for
            </h4>
         </div>
         <div className="button-div">
            <ParticleEffectButton
               duration={500}
               color="#6C17FF"
               hidden={hiddenParticles}
               onComplete={() => history.push("/")}
            >
               <div className="particle-effect-btn-div font-size-20 back-btn" onClick={animation}>
                  Back to Home Page
               </div>
            </ParticleEffectButton>
         </div>
      </div>
   );
};

export default Page404;

import React from 'react';

// styling
import './OnboardingNav.scss';

// @mui
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

// svg
import {ReactComponent as Logo} from '../../assets/SVGs/logo.svg';

const OnboardingNav = () => {
    return (
        <AppBar className="onboarding-nav" position="static" sx={{bgcolor: '#1d0040', px: 5, py: 2}}>
                <Logo className="logo" />
                <Typography variant="h6" className="onboarding-nav-title">Sign In</Typography>
        </AppBar>
    )
}

export default OnboardingNav;